import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Simplemodal from "src/components/simplemodal"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"

const simplemodalData = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
     #ctadisclosurenewtab-module--ctadisclaimer p {
      font-weight:300;
     }

     .footerlinks-module--br {
      font-family: "Lexend Deca", sans-serif;
      font-weight:300;
      color:#808080;
     }
     .footerlinks-module--br a {
      color:#808080;
     }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Simplemodal data={simplemodalData}>
      </Simplemodal>
      </section>
    </HomepageLayout>
  )
}
