import React from "react"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/simplemodal.module.scss"
import Data from "./data/simplemodal.json"
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"
import Footerlinks from "@tightrope/footerlinks/footer"


class simplemodal extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        isChrome: isChrome(),
        isFirefox: isFirefox(),
        isIeEdge: isIeEdge(),
        display: 'none',
        pageData: props.data,
        data: Data
      }
    }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    if(this.props.data){
      this.props.data.then(data => {
        const instanceData = Object.assign(this.state.data, data);
        this.setState({ data: instanceData});
      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
        window.mapParams();
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }

      });
    }

  }
  render() {
    return (
      <>
      <div id = {Styles.simplemodal}>
      <a href={this.state.data.cwsurl}><img className = {Styles.topLogo} src={this.state.data.topLogo} alt="Chrome Web Store"/></a>
	      <main>
	        <header className = {Styles.top}>
            <a href={this.state.data.cwsurl}><img src={this.state.data.cws} alt="Chrome Web Store"/></a>
	        </header>
	        <section className = {Styles.bottom}>
	          <h1 className = "hc_param">{this.state.data.heading1}</h1>
	          <h2>{this.state.data.heading2}</h2>
	          <h3>{this.state.data.heading3}</h3>
	          <button className = {`${Styles.download} cl1`} data-cy="cta" onClick = {() => window.triggerInstall()}>{this.state.data.button}</button>
	          <CtaDisclosureNewtab></CtaDisclosureNewtab>
	        </section>
	      </main>
       {this.props.children}
       </div>
      <Footerlinks></Footerlinks>
      </>
    )
  }
}
export default simplemodal
